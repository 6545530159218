.App {
  text-align: left;
  height: 100%;
  width: 100%;
}

.home_main {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 5s;
  overflow-y: scroll;
  overflow-x: hidden;
}

.home_main::-webkit-scrollbar {
  display: none;
}

.home_space {
  position: static;  
  width: 100%;
  height: 100%;
}

.home_space::after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(240,240,240,0), rgba(240,240,240, 1) 100%);
  width: 100%;
  height: 3em;
}

.home_title {
  position: absolute;
  top: 10%;
  right: 5%;
}

.header-major {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(240,240,240);
}
.header-major::after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0, 1) 100%);
  width: 100%;
  height: 3em;
}

.App-header {
  position: relative;
  left: 10vw;
  height: 60vh;
  width: 50vw;
  bottom: 5vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 10s;
}

.Name {
  height: 100%;
  color: white;
  font-size: calc(30px + 3vw);
  margin-bottom: calc(25px + 4vw);
}

.Name span{
  color: #00AAE6;
  font-weight: 350;
}

.Blurb {
  color: black;
  font-size: calc(10px + 1vw);
  margin-top: 0px;
  margin-bottom: calc(9px + 1vw);
}

.blurbs_link {
  color: #00AAE6;
  text-decoration: none;
}

.Image {
  height: 25vw;
  width: 25vw;
  position: relative;
  left: 15vw;
  bottom: 5vw;
  border-radius: 50%;
  box-shadow: 5px 5px 5px grey;
}

.ButtonBox {
  display: table;
  margin: 10px auto 0;
  list-style: none;
  pointer-events: auto;
  position: absolute;
  text-align: center;
  top: 7%;
  right: 5%;
}

.listitem {
  display: table-cell; 
  position: relative; 
  padding: 10px 10px;
}

.Button {
  padding: 0px 0px;   
  color: white;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: calc(7px + 1vw);
  font-weight: 300;
  font-family: 'Varela', sans-serif;
  text-decoration: none;
  display: inline-block;
}
.Button::after {    
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #00AAE6;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.Button:hover::after { 
  width: 100%; 
  left: 0; 
}

.publications_main {
  position: relative;
  top: 0;
  left: 0;
  width: 100%; 
  color: rgba(240,240,240, 1);
} 
.publications_main::after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(240,240,240,0), rgba(240,240,240, 1) 100%);
  width: 100%;
  height: 3em;
}

.publications_title {
  color: rgba(240,240,240, 1);
}

.publications_link {
  text-decoration: none;
  color: rgba(240,240,240, 1);
}

.publications_link:hover { 
  color: #00AAE6;
} 

.projects_main {
  position: relative;
  top: 0;
  left: 0;
  width: 100%; 
  background-color: rgb(240,240,240);
} 

.projects_text {
  position: relative;
  left: 10vw;
  width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 10vh;
  padding-bottom: 80px;
}

.project_box {
  width: 100%;
  margin: 10px 0px;
}

.project_link {
  text-decoration: none;
  color: black;
}
.project_link:hover { 
  color: #00AAE6;
}
.project_link span {
  font-size: calc(15px + 2vw)
}
.project_title {
  color: black;
}
.project_title span {
  font-size: calc(15px + 2vw)
}
.project_description {
  font-size: calc(10px + 0.5vw);
}

.bottom {
  width: 100%;
  padding-bottom: 100px;
}

.top_button {
  text-align: right;
  text-decoration: none;
  color: #00AAE6;
  font-size: 25px;
  padding-right: 10px;
  padding-left: 10px;
  position: fixed;
  bottom: 30px;
  right: 40px;
  outline: none;
  font-weight: 550;
  opacity: 0.6;
  background-color: transparent;
}

.top_button:hover{
  cursor: pointer;
  color: #7FD8EB;
  opacity: 1;
}

@media only screen and (max-width: 600px) {
  .home_space {
    pointer-events: none;
  }
  .App-header {
    width: 85vw;
    height: 50vh;
    bottom: 0;
  }

  .Image {
    height: calc(100px + 20vw);
    width: calc(100px + 20vw);
    left: calc(40vw - 50px);
    top: calc(-40px - 10vw);
  }

}

@media only screen and (max-height: 600px) {
  .Blurb {
    margin-bottom: 4px;
  }
  .App-header {
    width: 55vw;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}